// log the pageview with their URL
export var pageview = function pageview(url) {
  window.gtag('config', 'UA-46907238-1', {
    page_path: url
  });
}; // log specific events happening.

export var event = function event(_ref) {
  var action = _ref.action,
      params = _ref.params;
  window.gtag('event', action, params);
};