var Reducer = function Reducer(history, action) {
  if (action.type === 'route_changed') {
    var url = action.payload.url;

    if (history[history.length - 1] === url) {
      return history;
    }

    var newHistory = history;
    newHistory.push(url);
    return newHistory;
  }

  if (action.type === 'navigate_back') {
    var _newHistory = history;

    _newHistory.pop();

    return _newHistory;
  }
};

export default Reducer;