import {Col, Row} from "reactstrap";
import Link from "next/link";
import React from "react";
import dynamic from "next/dynamic";

let SearchBox = dynamic(
    () => import("../Mobile/SearchBox"),
    {ssr: false}
);


const Menu = ({onMenuClick}) => {
    return (
        <>
            <div className="my-4">
                <SearchBox onKeyDown={onMenuClick} />
            </div>
            <div className="font-weight-bold text-uppercase">
                Vakantiehuizen
            </div>
            <Row>
                <Col>
                    <ul className="list-unstyled text-primary">
                        <li onClick={() => onMenuClick()}><Link href="/vakantiehuis/engeland"><a>Engeland</a></Link></li>
                        <li onClick={() => onMenuClick()}><Link href="/vakantiehuis/schotland"><a>Schotland</a></Link></li>
                    </ul>
                </Col>
                <Col>
                    <ul className="list-unstyled text-primary">
                        <li onClick={() => onMenuClick()}><Link href="/vakantiehuis/wales"><a>Wales</a></Link></li>
                        <li onClick={() => onMenuClick()}><Link href="/vakantiehuis/ierland"><a>Ierland</a></Link></li>
                    </ul>
                </Col>
            </Row>

            <div className="font-weight-bold text-uppercase">
                Inspiratie
            </div>
            <Row>
                <Col>
                    <ul className="list-unstyled text-primary">
                        <li onClick={() => onMenuClick()}><Link href="/#inspiratie"><a>Speciale interesses</a></Link></li>
                        <li onClick={() => onMenuClick()}><Link href="/camper#inspiratie"><a>Camper rondreizen</a></Link></li>
                    </ul>
                </Col>
            </Row>

            <div className="font-weight-bold text-uppercase">
                Onze diensten
            </div>
            <Row>
                <Col>
                    <ul className="list-unstyled text-primary">
                        <li onClick={() => onMenuClick()}><Link href="/verblijf-op-maat"><a>Een verblijf op maat</a></Link></li>
                        <li onClick={() => onMenuClick()}><Link href="/camper"><a>Camper huren</a></Link></li>
                        <li onClick={() => onMenuClick()}><Link href="/overtocht"><a>Overtocht regelen</a></Link></li>
                        <li onClick={() => onMenuClick()}><Link href="/verzekeringen"><a>Verzekering afsluiten</a></Link></li>
                    </ul>
                </Col>
            </Row>

            <div className="font-weight-bold text-uppercase">
                Contact opnemen?
            </div>
            <Row>
                <Col>
                    <ul className="list-unstyled text-primary">
                        <li onClick={() => onMenuClick()}><Link href="/contact"><a>Bekijk hoe u ons kunt bereiken</a></Link></li>
                    </ul>
                </Col>
            </Row>
        </>
    )
}

export default Menu;
